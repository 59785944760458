import React, {useEffect, useState} from 'react';
import {initialView3DViewportParams, View3DViewportParams} from '../../components/molecules/Viewport/3D/View3DViewport';
import {AnalysisTypeMap, CTFiltersMap} from '../../components/molecules/Viewport/3D/types/pointCloudTypes';
import {usePartPointClouds} from '../../components/molecules/Viewport/3D/pointCloudHooks/usePartPointClouds';
import {usePointCloudEffects} from '../../components/molecules/Viewport/3D/pointCloudHooks/usePointCloudEffects';
import Base3DViewport, {initialParams} from '../../components/molecules/Viewport/3D/Base3DViewport';
import {CTResultsViewportSidebar} from '../../components/molecules/Viewport/3D/Sidebar/View3DViewportSidebar';
import {showOverlayMessage, ViewportMessage} from '../../components/molecules/Viewport/3D/ViewportMessage';
import {initialPointCloudParams} from '../../components/molecules/Viewport/3D/types/params';
import {ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import {usePartStoreActions} from '../../store/actions';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/reducers';
import {cloneDeep} from 'lodash';
import {AnalysisType3D, CTDefectType3D} from '@common/api/models/builds/data/defects/IDefect';
import {objMapValues} from '../../utils/objectFunctions';
import {useExtraSmallScreenSize} from '../../utils/utilHooks';
import {toggleAnalysisTypeVisibility} from '../../components/molecules/Viewport/3D/viewportFunctions/control';

export interface CTResultsViewportProps {
  ctReport: ICTReportGETResponse;
  activeDefectId: number | null;
  ctFilters: CTFiltersMap;
  stageHeight?: number;
}

const initialPartPreviewParams: View3DViewportParams = {
  ...initialParams,
  ...initialPointCloudParams,
  clippingPlaneEnabled: false,
  availableParts: [],
  selectedParts: [],
  availableAnalysisTypes: {} as AnalysisTypeMap<boolean>,
  selectedAnalysisTypes: {} as AnalysisTypeMap<boolean>,
  pointSize: 0.1,
  modelOpacity: 0.1,
  showGrid: false,
  throughPlane: true,
  pointLimit: 6e5,
  rotation: undefined,
  isCtResults: true,
  ctFilters: objMapValues(CTDefectType3D, () => ({})) as CTFiltersMap,
};

function CTResultsViewport({ctReport, stageHeight, activeDefectId, ctFilters}: CTResultsViewportProps) {
  const partStoreActions = usePartStoreActions();
  const [params, setParams] = useState<View3DViewportParams>({
    ...initialPartPreviewParams,
    availableAnalysisTypes: objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>,
    selectedAnalysisTypes: {
      ...(objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>),
      [AnalysisType3D.Model]: true,
      [AnalysisType3D.Pore]: true,
    },
  });
  const isSmallScreen = useExtraSmallScreenSize();

  const rerenderRef = React.useRef(() => {});
  // Function to force a rerender of the 3D viewport
  const renderScene = rerenderRef.current;

  const {viewportState, pointClouds, sceneBounds, availableAnalysisTypes} = usePartPointClouds(
    [ctReport.partUuid],
    params.selectedAnalysisTypes,
    params,
    renderScene
  );

  useEffect(() => {
    setParams((params) => ({
      ...params,
      ctFilters,
      activeDefectId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctFilters, activeDefectId]);

  useEffect(() => {
    const filter = {uuid: ctReport.partUuid};
    partStoreActions.ensureConsistent({...filter, includeFirstLayer: true}, filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctReport.partUuid]);

  useEffect(() => {
    // Update params if available analysis types changes
    setParams((params) => ({
      ...params,
      availableAnalysisTypes,
      // Select model analysis type if none are selected
      selectedAnalysisTypes: Object.values(params.selectedAnalysisTypes).some((enabled) => enabled)
        ? params.selectedAnalysisTypes
        : initialView3DViewportParams.selectedAnalysisTypes,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAnalysisTypes]);

  const part = useSelector((state: RootState) => state.partStore.byId[ctReport.partUuid]);

  useEffect(() => {
    if (part) {
      setParams((params) => ({
        ...params,
        selectedParts: cloneDeep([part]),
        availableParts: cloneDeep([part]),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part]);

  useEffect(() => {
    toggleAnalysisTypeVisibility(params, setParams, pointClouds, renderScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedAnalysisTypes, pointClouds.numParts, renderScene]);

  usePointCloudEffects(pointClouds, params, renderScene, viewportState);

  return (
    <Base3DViewport
      sceneGroup={pointClouds}
      sceneBounds={sceneBounds}
      params={params}
      sidebar={
        <CTResultsViewportSidebar
          hidePartSelection
          params={params}
          sceneBounds={sceneBounds}
          setParams={setParams}
          resetParams={() =>
            setParams({
              ...initialPartPreviewParams,
              availableParts: params.availableParts,
              selectedParts: params.selectedParts,
              availableAnalysisTypes: params.availableAnalysisTypes,
            })
          }
          viewportState={viewportState}
        />
      }
      height={stageHeight}
      sidebarInitOpen={!isSmallScreen}
      overlayMessage={
        <ViewportMessage
          viewportState={viewportState}
          noSelectionTitle="No part selected"
          noSelectionMessage="Select a part in the tree to preview."
        />
      }
      showOverlayMessage={showOverlayMessage(viewportState)}
      showHelpers={['viewing', 'loading'].includes(viewportState)}
      rerenderRef={rerenderRef}
    />
  );
}

export default CTResultsViewport;
