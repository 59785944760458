import {CTReportStatus, ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import React from 'react';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import {ChipStatus} from '../../../../components/atoms/Status/Status';
import {Delete} from '@material-ui/icons';
import {Box, IconButton, Tooltip} from '@material-ui/core';

export const ctReportTableSchema = [
  {
    title: 'Name',
    field: 'name',
    width: 'auto',
    render: (row: ICTReportGETResponse) => {
      return <EllipsisTextColumnTooltip>{row.name}</EllipsisTextColumnTooltip>;
    },
  },
  {
    title: 'Part',
    field: 'partName',
  },
  {
    title: 'Status',
    field: 'status',
    width: '124px',
    render: (row: ICTReportGETResponse) => {
      switch (row.status) {
        case CTReportStatus.DRAFT:
          return <ChipStatus message="Draft" variant="none" />;
        case CTReportStatus.SUBMITTED:
          return <ChipStatus message="Submitted" variant="info" />;
        case CTReportStatus.GENERATING:
          return <ChipStatus message="Generating" variant="info" />;
        case CTReportStatus.ALIGNMENT:
          return <ChipStatus message="Alignment" variant="warning" />;
        case CTReportStatus.ALIGNING:
          return <ChipStatus message="Aligning" variant="warning" />;
        case CTReportStatus.COMPLETED:
          return <ChipStatus message="Completed" variant="success" />;
        case CTReportStatus.FAILED:
          return <ChipStatus message="Failed" variant="error" />;
        default:
          return <></>;
      }
    },
  },
  {
    title: 'Voxel Size',
    field: 'voxelSize',
  },
  {
    title: 'Created At',
    field: 'createdAt',
    render: (row: ICTReportGETResponse) => <DateWithTimeTooltip timestamp={row.createdAt} />,
  },
  {
    title: 'Actions',
    sorting: false,
    width: '72px',
    render: (row: ICTReportGETResponse & {onDelete: () => void}) => <ReportActions row={row} />,
  },
];

const ReportActions = ({row}: {row: ICTReportGETResponse & {onDelete: () => void}}) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip title="Remove">
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            row.onDelete();
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
